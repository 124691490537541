<template>
    <div class="content_">
          <div>
            <div>
              <h2>关于厦门虎鲸梦想网络科技有限公司</h2>
            </div>
            <div>
                <div>
                    <h4>成立于2021年。</h4>
                </div>
                <div>
                    <h4>LOGO设计于2013年。</h4>
                </div>
                <div>
                    <h4>专注于互联网前沿技术与行业动态。</h4>
                </div>
                <div>
                    <h4>致力打造技术与产业完美结合的产品，完善产业的互联网生态。</h4>
                </div>
                
            </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'about'
  
  }
  </script>
  
  <style>

  </style>